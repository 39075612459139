import React, { ReactNode } from 'react'
import LinkArrow from '../assets/link-arrow.svg'
import RegisterIcon from '../assets/register.svg'
import InfoIcon from '../assets/info.svg'
import SendIcon from '../assets/send.svg'
import * as styles from './Button.module.scss'
import { Link } from 'gatsby-plugin-intl'

const buttonIcons = {
  link: LinkArrow,
  register: RegisterIcon,
  info: InfoIcon,
  send: SendIcon,
}

export interface ButtonProps {
  children?: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  icon?: keyof typeof buttonIcons;
  iconPosition?: 'start' | 'end';
  href?: string;
  onClick?: () => void;
  noMarginBottom?: boolean;
  openInNewTab?: boolean;
  disabled?: boolean;
}

const Button = ({
  children,
  type,
  align = 'center',
  icon,
  iconPosition = 'start',
  href,
  onClick,
  noMarginBottom,
  openInNewTab,
  disabled,
}: ButtonProps) => {
  const renderIcon = () => {
    if (!icon)
      return null

    const IconCmp = buttonIcons[icon]
    return <IconCmp />
  }

  const buttonContent = (<>
    <div className={styles.startIcon}>
      {iconPosition === 'start' && renderIcon()}
    </div>
    <div>
      {children}
    </div>
    <div className={styles.endIcon}>
      {iconPosition === 'end' && renderIcon()}
    </div>
  </>)

  const linkElemProps = {
    className: styles.button,
    onClick,
    style: {
      flex: align === 'stretch' ? 1 : undefined
    }
  }

  const wrapperProps = {
    className: styles.wrapper,
    style: {
      justifyContent: align,
      marginBottom: noMarginBottom ? 0 : undefined
    }
  }

  if (href) {
    return (
      <div {...wrapperProps}>
        {(href.startsWith('http') || openInNewTab) ? (
          <a
            {...linkElemProps}
            href={href}
            target='_blank'
          >
              {buttonContent}
          </a>
        ) : (
          <Link
            {...linkElemProps}
            to={href}
          >
              {buttonContent}
          </Link>
        )}
      </div>
    )
  } else {
    return (
      <div {...wrapperProps}>
        <button
          {...linkElemProps}
          disabled={disabled}
          type={type}
        >
            {buttonContent}
        </button>
      </div>
    )
  }
}

export default Button
