import React, { useState, useEffect } from 'react'

import * as styles from './TextField.module.scss'

interface Props {
  className?: string;
  suffix?: string;
  value: string | number;
  autocomplete?: string;
  label: string;
  name?: string;
  onChange: (newValue: string) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  autofocus?: boolean;
  type?: string;
  multiline?: boolean;
  max?: number;
  min?: number;
  hasError?: boolean;
}

const TextField = ({
  className,
  suffix,
  value,
  autocomplete,
  label,
  name,
  onChange,
  onClick,
  autofocus,
  type = 'text',
  multiline,
  max,
  min,
  hasError,
}: Props) => {
  const [hasContent, setHasContent] = useState(false)

  useEffect(() => {
    setHasContent(value !== undefined && value !== null && value !== '')
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if(type === 'number') {
      let newValue = parseInt(event.target.value || '0')
      if(max !== undefined)
        newValue = Math.min(newValue, max)
      if(min !== undefined)
        newValue = Math.max(newValue, min)
      if (onChange)
        onChange(newValue.toString())
    } else {
      if (onChange)
        onChange(event.target.value)
    }
  }

  const ElementType = multiline ? 'textarea' : 'input'

  return (
    <div
      className={[
        styles.wrapper,
        hasContent ? styles.hasContent : null,
        hasError ? styles.hasError : null,
        className
      ].join(' ')}
      onClick={onClick}
    >
      <ElementType type={type} autoComplete={autocomplete} value={value} name={name || label} onChange={handleChange} autoFocus={autofocus}/>
      <span style={{marginTop: '.1rem'}}>{suffix}</span>
      <label>{label}</label>
    </div>
  )
}

export default TextField
