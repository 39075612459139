// extracted by mini-css-extract-plugin
export var card = "KurseUndVeranstaltungen-module--card--eHmpp";
export var cards = "KurseUndVeranstaltungen-module--cards--vtENb";
export var filler = "KurseUndVeranstaltungen-module--filler--lnpV6";
export var icon = "KurseUndVeranstaltungen-module--icon--Mmmg4";
export var iconRow = "KurseUndVeranstaltungen-module--iconRow--PGy1B";
export var label = "KurseUndVeranstaltungen-module--label--WuqBy";
export var pdfLink = "KurseUndVeranstaltungen-module--pdfLink--5b2rJ";
export var price = "KurseUndVeranstaltungen-module--price--lN1iG";
export var priceSection = "KurseUndVeranstaltungen-module--priceSection--Olais";
export var registerForm = "KurseUndVeranstaltungen-module--registerForm--tZrEn";
export var registerModal = "KurseUndVeranstaltungen-module--registerModal--LYrUr";
export var registerModalOverlay = "KurseUndVeranstaltungen-module--registerModalOverlay--lDdXZ";
export var text = "KurseUndVeranstaltungen-module--text--IA60+";
export var title = "KurseUndVeranstaltungen-module--title--qF+6J";